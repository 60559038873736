import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useCheckExistUser } from '_api/useUsers';
import { showToast } from '_utils';
import { screenMargin, titleFontSize } from '_utils/sizes';
import {
    ErrorMessage,
    HeaderWithNav,
    HeimeText,
    OpinionatedSafeArea,
    PhoneNumberInput,
    PrimaryButton,
} from 'Components';
import { cleanPhoneNumber } from 'types/User';
import { EnterPhonePropsSchema, useTenantEdit } from '../TenantEditNavigation';

const EnterPhone = (props: unknown): ReactElement => {
    const { apartmentId } = EnterPhonePropsSchema.parse(props).route.params;
    const { t } = useTranslation();
    const { navigate } = useTenantEdit();

    const [phone, setPhone] = useState('');

    const { mutateAsync, isPending: isLoading } = useCheckExistUser();

    const handleContinue = async () => {
        if (phone) {
            const result = await mutateAsync(
                { phone: cleanPhoneNumber(phone) },
                {
                    onError: () => {
                        showToast({
                            type: 'error',
                            text: t('global:error_saving_description'),
                            header: t('global:error_saving_header'),
                        });
                    },
                },
            );

            if (result) {
                navigate('EditDetails', {
                    type: 'addUser',
                    user: { tenant_role: 'owner', status: 'active', avatar: null, email: '', ...result },
                    apartmentId,
                });
            } else {
                navigate('EditDetails', {
                    type: 'createUser',
                    phone,
                    apartmentId,
                });
            }
        }
    };

    return (
        <OpinionatedSafeArea>
            <HeaderWithNav title={t('tenantEdit:addUser')} />
            <ScrollView
                bounces={false}
                style={styles.scrollContainer}
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={styles.container}
            >
                <HeimeText style={styles.explainer}>{t('tenantEdit:addExplainer')}</HeimeText>
                <PhoneNumberInput
                    label={t('tenantEdit:phone')}
                    placeholder={t('tenantEdit:phonePlaceholder')}
                    value={phone}
                    onChange={setPhone}
                />
                {phone && phone.length > 24 ? <ErrorMessage>{t('tenantEdit:phoneTooLong')}</ErrorMessage> : null}
            </ScrollView>
            <PrimaryButton
                onPress={handleContinue}
                text={t('tenantEdit:continue')}
                status={isLoading ? 'loading' : !phone ? 'disabled' : null}
                bottomAction="modal"
            />
        </OpinionatedSafeArea>
    );
};

const styles = StyleSheet.create({
    scrollContainer: { height: '100%' },
    explainer: {
        fontSize: titleFontSize,
        marginBottom: screenMargin * 2,
        marginTop: screenMargin * 2,
    },
    container: {
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
        flex: 1,
    },
});

export default EnterPhone;
