import React, { ReactElement, useEffect, useContext, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useBottomSpacer } from '_utils/hooks';
import { useGetUsers } from '../../_api/useUsers';
import { useNeighbourSearch, WH, WW } from '../../_utils';
import useNavigateToProfile from '../../_utils/hooks/useNavigateToProfile';
import { screenMargin } from '../../_utils/sizes';
import { ThemeContext } from '../../_utils/themeContext';
import {
    EmptyList,
    ExpandableSearchInput,
    Loader,
    QuerySectionedView,
    SectionHeader,
    UserListItem,
} from '../../Components';
import { ExternalUserProfile } from '../../types/User';

interface NeighborListProps {
    filter?: 'alphabetic' | 'apartment';
}

const NeighborList = ({ filter = 'alphabetic' }: NeighborListProps): ReactElement => {
    const { theme } = useContext(ThemeContext);
    const paddingBottom = useBottomSpacer();
    const { t } = useTranslation();
    const handleOnPress = useNavigateToProfile();
    const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch, isRefetching } =
        useGetUsers(true);

    const { filteredData, handleSearchTextChange, searchString } = useNeighbourSearch(data, filter, true);

    useEffect(() => {
        if (!isFetchingNextPage && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    if (isError) {
        throw error;
    }

    if (isLoading) {
        return <Loader />;
    }

    // We have to use put querySectionedView inside because of searchInput remounting when hitting emptyComponent  (facebook/react-native#23400, )
    return (
        <FlatList
            renderItem={({ item }) => item}
            style={{ paddingTop: WH * 0.02, backgroundColor: theme.mainBackground }}
            data={[
                <>
                    <View key="search" style={styles.searchInput}>
                        <ExpandableSearchInput
                            key="search"
                            value={searchString}
                            onChange={handleSearchTextChange}
                            buttonText={t('neighbours:searchPlaceholder')}
                        />
                    </View>
                    {filteredData.length === 0 ? <EmptyList message={t('neighbours:noUserFound')} /> : null}
                </>,
                <QuerySectionedView<
                    ExternalUserProfile & {
                        name: string;
                    }
                >
                    key={filteredData.length}
                    sections={[...filteredData]}
                    renderItem={({ item }) => (
                        <TouchableOpacity
                            onPress={() => {
                                handleOnPress(item.id);
                            }}
                            style={styles.userItem}
                        >
                            <UserListItem
                                titleColor={theme.darkGreen}
                                desc={
                                    filter === 'alphabetic'
                                        ? (item?.cooperative_apartments ?? []).map(({ name }) => name).join(',')
                                        : ''
                                }
                                title={item.name}
                                id={item.id}
                            />
                        </TouchableOpacity>
                    )}
                    header={NullableSectionHeader}
                    keyboardShouldPersistTaps="handled"
                    isRefreshing={isRefetching}
                    onRefresh={refetch}
                />,
                <View key="padding" style={{ paddingBottom }} />,
            ]}
        />
    );
};

const NullableSectionHeader: ComponentProps<
    typeof QuerySectionedView<
        | { id: 'search' }
        | (ExternalUserProfile & {
              name: string;
          })
    >
>['header'] = ({ title }): ReactElement | null => {
    if (title) {
        return <SectionHeader title={title.toUpperCase()} />;
    }
    return null;
};

const styles = StyleSheet.create({
    empty: { justifyContent: 'center', flexGrow: 1 },
    iconStyle: {
        marginLeft: WW * 0.01,
        marginRight: WW * 0.01,
    },
    searchInput: {
        marginLeft: screenMargin / 2,
        marginRight: screenMargin / 2,
        marginBottom: screenMargin / 2,
    },
    userItem: {
        paddingLeft: screenMargin,
        paddingRight: screenMargin,
    },
});

export default NeighborList;
